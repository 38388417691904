import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Table, Modal, ToggleButton, Alert } from 'react-bootstrap';

import { useSearchParams } from "react-router-dom";

import SignatureCanvas from 'react-signature-canvas'

import {
    fetchCheckOrderCanFitting,
    fetchSearchFittingListByName,
    fetchSaveFittingRecord,
    fetchFindFittingRecord,
    changeSearchName,
    changeSearchAlertMsg,
    changeSelectedSuitId,
    changeFittingRecord,
    changeSuitRecord,
    changeShowSaveFittingRecord,
    showAlert,
    changeIncompleteData
} from './fittingSlice';

import { ToastAlert } from './ToastAlert';
import {
    colorDisplay,
    sizingDisplay
} from '../../utils/Constants';
import {
    sleep
} from '../../utils/Utils';

import '@fortawesome/fontawesome-free/css/all.min.css'
import styles from './Fitting.module.css';

export function Fitting() {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const loading = useSelector(state => state.fitting.loading);
    const orderCanFitting = useSelector(state => state.fitting.orderCanFitting);
    const searchName = useSelector(state => state.fitting.searchName);
    const searchAlertMsg = useSelector(state => state.fitting.searchAlertMsg);
    const searchList = useSelector(state => state.fitting.searchList);
    const selectedSuit = useSelector(state => state.fitting.selectedSuit);
    const fittingRecord = useSelector(state => state.fitting.fittingRecord);
    const canEdit = useSelector(state => state.fitting.canEdit);
    const incompleteData = useSelector(state => state.fitting.incompleteData);

    let sigPad = {}

    let content = null;
    let searchListDiv = null;
    let searchAlertDiv = null;
    let searchTableContent = [];
    let searchTableRows = [];
    let incompleteDataDiv = [];

    if (searchList.length > 0) {
        searchTableContent.push(
            <thead>
                <tr>
                    <th>項次</th>
                    <th>分隊</th>
                    <th>姓名</th>
                    <th style={{ width: "100px" }}></th>
                </tr>
            </thead>
        )
        for (var row = 0; row < searchList.length; row++) {
            let searchMap = searchList[row];
            let fittingBtn = null;
            if (parseInt(searchMap.fitted_cnt) > 0) {
                fittingBtn = (
                    <Button variant="outline-secondary" style={{ width: 78 }} onClick={() => {
                        dispatch(changeSelectedSuitId(searchMap.suit_id));
                        dispatch(fetchFindFittingRecord({ orderId: orderCanFitting.data.order_id, suitId: searchMap.suit_id }));
                        handleShowModal();
                    }}>已套量</Button>
                )
            } else {
                fittingBtn = (
                    <Button variant="outline-primary" onClick={() => {
                        dispatch(changeSelectedSuitId(searchMap.suit_id));
                        dispatch(fetchFindFittingRecord({ orderId: orderCanFitting.data.order_id, suitId: searchMap.suit_id }));
                        dispatch(changeShowSaveFittingRecord(false));
                        handleShowModal();
                    }}><i className="fas fa-ruler"></i>{' '}套量</Button>
                )
            }
            searchTableRows.push(
                <tr key={searchMap.suit_id}>
                    <td>{searchMap.serial_no}</td>
                    <td>{searchMap.firefighter_squadron}</td>
                    <td>{searchMap.firefighter_name}</td>
                    <td>{fittingBtn}</td>
                </tr>
            )
        }
        searchTableContent.push(
            <tbody>
                {searchTableRows}
            </tbody>
        )
        searchListDiv = (
            <Table striped bordered hover>
                {searchTableContent}
            </Table>
        )
    }

    if (searchAlertMsg) {
        searchAlertDiv = (
            <Alert key="searchAlert" variant="warning">
                {searchAlertMsg}
            </Alert>
        )
    }

    if (incompleteData) {
        incompleteDataDiv = (
            <>
                <span style={{ color: 'tomato' }}><b>資料不完整：</b></span><br />
                <span>{incompleteData}</span>
                <br />
                <hr />
            </>
        )
    }

    if (loading) {
        content = (
            <div className="text-center" style={{ margin: 32 }}>
                <div className="spinner-border" role="status" />
            </div>
        )
    } else if (orderCanFitting) {
        if (orderCanFitting.statusCode !== '0') {
            content = (
                <div className="text-center" style={{ margin: 32 }}>
                    <span>Error</span>
                </div>
            )
        } else {
            if (orderCanFitting.data == null) {
                content = (
                    <div className="text-center" style={{ margin: 32 }}>
                        <h5>無此訂單 或 該訂單未開啟線上套量</h5>
                    </div>
                )
            } else {
                content = (
                    <div style={{ margin: 16 }}>
                        <h5>套量訂單：{orderCanFitting.data.fitting_name}</h5>
                        <form className="d-flex">
                            <input className="form-control me-2" type="search" placeholder="請輸入姓名" aria-label="Search" value={searchName} onChange={
                                (event) => {
                                    dispatch(changeSearchName(event.target.value));
                                }
                            } />
                            <button className="btn btn-primary" type="button" onClick={
                                (event) => {
                                    if (searchName) {
                                        dispatch(fetchSearchFittingListByName({
                                            orderId: orderCanFitting.data.order_id,
                                            searchName: searchName
                                        }));
                                        dispatch(changeSearchAlertMsg(null));
                                    } else {
                                        dispatch(changeSearchAlertMsg('搜尋不得為空值'));
                                    }
                                }
                            }><i className="fas fa-search"></i></button>
                        </form>
                        <br />
                        {searchAlertDiv}
                        {searchListDiv}
                    </div>
                )
            }
        }
    }

    //--- ModalContent Start ---
    const rowButtonOnClick = (row, type, value) => {
        dispatch(changeFittingRecord({ row: row, type: type + '_value', value: value }))
    }
    const rowNoteChange = (row, value) => {
        dispatch(changeFittingRecord({ row: row, type: 'note_value', value: value }))
    }

    const suitRecordOnChange = (type, value) => {
        dispatch(changeSuitRecord({ type: type, value: value }))
    }

    const saveFitting = async (fittingRecord) => {
        let signatureData = sigPad.getTrimmedCanvas().toDataURL('image/png');
        dispatch(fetchSaveFittingRecord({
            orderId: searchParams.get('order_id'),
            suitId: selectedSuit.suit_id,
            suitRecord: selectedSuit,
            fittingRecord: fittingRecord,
            signature: signatureData
        }));
        await sleep(500);
        dispatch(fetchFindFittingRecord({
            orderId: searchParams.get('order_id'),
            suitId: selectedSuit.suit_id
        }));
        dispatch(fetchSearchFittingListByName({
            orderId: searchParams.get('order_id'),
            searchName: searchName
        }));
    }

    const validateSignature = () => {
        let signatureData = sigPad.getTrimmedCanvas().toDataURL('image/png');
        if (signatureData.length < 2000) {  // 空白好像是 1766 //TODO: 再確認有沒有其他比較好的方法
            dispatch(showAlert({ toastAlertStyle: 'danger', toastAlertMessage: '簽名為必填欄位' }));
            return false;
        }
        return true;
    }

    // 填寫身高體重的話，必須勾選 check_personal_info 欄位
    const validateCheckPersonalInfo = () => {
        if (selectedSuit.body_height || selectedSuit.body_weight) {
            if (!selectedSuit.check_personal_info) {
                dispatch(showAlert({ toastAlertStyle: 'danger', toastAlertMessage: '請勾選代表同意「固巧快」取得並儲存相關資料 或 刪除身高體重資訊' }));
                return false;
            }
        }
        return true;
    }

    const checkFittingIncomplete = (fittingRecord) => {
        let returnText = '';
        if (fittingRecord.length > 0) {
            for (var row = 0; row < fittingRecord.length; row++) {
                let bespokeArr = fittingRecord[row];
                if (bespokeArr['color_values'] && (!bespokeArr['color_value'])) {
                    returnText = returnText + bespokeArr['alias_name'] + '顏色未選取，';
                }
                if (bespokeArr['size_values'] && (!bespokeArr['size_value'])) {
                    returnText = returnText + bespokeArr['alias_name'] + '尺寸未選取，';
                }
            }
        }
        dispatch(changeIncompleteData(returnText));
        return;
    }

    let modalContent = [];

    if (fittingRecord.length > 0) {
        for (var row = 0; row < fittingRecord.length; row++) {
            let bespokeArr = fittingRecord[row];
            // console.log(bespokeArr)
            // console.log(bespokeArr['color_value']);
            let k = row;

            // 顏色
            let colorTd = [];
            if (bespokeArr.fitting_color === 'Y') {

                let toggleButtonColorArr = [];
                // console.log(bespokeArr.color_values);
                let colorSplit = bespokeArr.color_values.split(',');
                let colorRowId = 'color_' + row;
                for (let j = 0; j < colorSplit.length; j++) {
                    let value = colorSplit[j]
                    let colorId = colorRowId + "_" + value;
                    // console.log(value);
                    // console.log(bespokeArr);
                    let checked = (value === bespokeArr['color_value']);
                    toggleButtonColorArr.push(
                        (
                            <ToggleButton
                                id={colorId}
                                type="radio"
                                variant='outline-primary'
                                name={colorId}
                                value={value}
                                checked={checked}
                                onClick={() => rowButtonOnClick(k, 'color', value)}
                                disabled={!canEdit}
                            > {colorDisplay(value)}
                            </ToggleButton>
                        )
                    );
                }
                if (colorSplit.length > 0) {
                    colorTd = (
                        <>
                            <tr>
                                <td>
                                    <Form.Label>顏色</Form.Label><br />
                                    {toggleButtonColorArr}
                                </td>
                            </tr>
                        </>
                    )
                }
            }
            // 尺吋
            let sizeTd = [];
            if (bespokeArr.fitting_size === 'Y') {
                let toggleButtonSizeArr = [];
                let sizeSplit = bespokeArr.size_values.split(',');
                let sizeRowId = 'size_' + row;
                for (let j = 0; j < sizeSplit.length; j++) {
                    let value = sizeSplit[j]
                    let sizeId = sizeRowId + "_" + value;
                    let checked = (value === bespokeArr['size_value']);
                    toggleButtonSizeArr.push(
                        (
                            <ToggleButton
                                id={sizeId}
                                type="radio"
                                variant='outline-primary'
                                name={sizeId}
                                value={value}
                                checked={checked}
                                onClick={() => rowButtonOnClick(k, 'size', value)}
                                disabled={!canEdit}
                            > {sizingDisplay(value)}
                            </ToggleButton>
                        )
                    );
                }
                if (sizeSplit.length > 0) {
                    sizeTd = (
                        <>
                            <tr>
                                <td>
                                    <Form.Label>尺寸</Form.Label><br />
                                    {toggleButtonSizeArr}
                                </td>
                            </tr>
                        </>
                    )
                }
            }
            // 備註
            if (bespokeArr && !bespokeArr['note_value']) {
                dispatch(changeFittingRecord({ row: k, type: 'note_value', value: '' }))
            }

            modalContent.push(
                (
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <th>
                                    <span style={{ fontSize: 20 }}>{bespokeArr.alias_name}</span>
                                </th>
                            </tr>
                            {colorTd}
                            {sizeTd}
                            <tr>
                                <td>
                                    <Form.Label>備註</Form.Label><br />
                                    <Form.Control as="textarea" rows={3} value={bespokeArr['note_value']} onChange={(event) => {
                                        rowNoteChange(k, event.target.value);
                                    }}
                                        disabled={!canEdit}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )
            );
        }
    }
    let signatureDiv = [];
    if (canEdit) {
        signatureDiv = (
            <SignatureCanvas
                canvasProps={{ width: '300px', height: '165px', className: 'sigCanvas' }}
                backgroundColor={'#FFF'}
                ref={(ref) => { sigPad = ref }}
            />
        )
    } else {
        signatureDiv = (
            <img src={selectedSuit.signature} />
        )
    }
    modalContent.push(
        (
            <>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td>
                                <Form.Group controlId="body_height">
                                    <Form.Label>身高</Form.Label>
                                    <Form.Control type="text" value={selectedSuit.body_height} onChange={(event) => {
                                        suitRecordOnChange('body_height', event.target.value);
                                    }}
                                        disabled={!canEdit}
                                    />
                                </Form.Group>
                                <Form.Group controlId="body_weight">
                                    <Form.Label>體重</Form.Label>
                                    <Form.Control type="text" value={selectedSuit.body_weight} onChange={(event) => {
                                        suitRecordOnChange('body_weight', event.target.value);
                                    }}
                                        disabled={!canEdit}
                                    />
                                </Form.Group>
                                <div style={{ paddingTop: 10 }}>
                                    <Form.Check inline checked={selectedSuit.check_personal_info} disabled={!canEdit} onClick={(event) => {
                                        if (event.target.checked) {
                                            suitRecordOnChange('check_personal_info', true);
                                        } else {
                                            suitRecordOnChange('check_personal_info', false);
                                        }
                                    }} />身高體重非必要資訊，但填寫能幫助系統更完善，<span style={{ color: 'tomato' }}>勾選代表同意「固巧快」取得並儲存相關資料</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td>
                                <Form.Group controlId="signature">
                                    <Form.Label style={{ paddingBottom: 10, paddingRight: 153, fontSize: 20 }}><b>簽名</b> <span style={{ color: 'red' }}>(必填)</span></Form.Label>{' '}
                                    <Button variant="primary" size="sm" onClick={() => {
                                        sigPad.clear();
                                    }}
                                        disabled={!canEdit}
                                    >清除</Button>
                                    <br />
                                    {signatureDiv}
                                </Form.Group>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    );

    let modalFooter = [];
    if (canEdit) {
        modalFooter = (
            <>
                <Button variant="secondary" onClick={handleCloseModal}>
                    取消
                </Button>
                <Button variant="primary" onClick={() => {
                    if (!validateSignature()) {
                        return;
                    }
                    if (!validateCheckPersonalInfo()) {
                        return;
                    }
                    checkFittingIncomplete(fittingRecord);
                    setShowConfirmModal(true);
                }}>
                    完成套量
                </Button>
            </>
        )
    } else {
        modalFooter = (
            <>
                <span style={{ color: 'tomato' }}>已完成套量，如資料有誤需修改，請洽服務人員</span>
                <Button variant="secondary" onClick={handleCloseModal}>
                    關閉
                </Button>
            </>
        )
    }
    //--- ModalContent End ---

    useEffect(() => {
        dispatch(fetchCheckOrderCanFitting(
            { orderId: searchParams.get('order_id'), checkId: searchParams.get('check_id') }
        ));
        return () => {
        };
    }, []);

    return (
        <Container>
            {content}
            <Modal
                show={showModal}
                fullscreen={true}
                backdrop="static"
                keyboard={false}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>套量 - {selectedSuit.serial_no} - {selectedSuit.firefighter_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    {modalFooter}
                </Modal.Footer>
                <ToastAlert />
            </Modal>
            <Modal
                show={showConfirmModal}
                size="sm"
                backdrop="static"
                onHide={() => {
                    setShowConfirmModal(false);
                }}
            >
                <Modal.Body style={{ backgroundColor: '#FFF3CD' }}>
                    {incompleteDataDiv}
                    一旦送出後，無法再從網頁自行修改，如資料有誤需修改，請洽服務人員 <br />
                    <span style={{ color: 'tomato' }}>確認送出？</span>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#FFF3CD' }}>
                    <Button variant="secondary" onClick={() => {
                        setShowConfirmModal(false);
                    }}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={() => {
                        saveFitting(fittingRecord);
                        setShowConfirmModal(false);
                    }}>
                        確認送出
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}